<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="id" label="id" width="80px"> </el-table-column>
			<el-table-column prop="title" label="活动名称"> </el-table-column>

			<el-table-column label="是否关联">
				<template slot-scope="scope">
					<span
						v-if="scope.row.sideevent == $route.query.id"
						style="color: red; font-size: 18px"
						>已关联</span
					>
					<span v-else>未关联</span>
				</template>
			</el-table-column>

			<el-table-column prop="start_time" label="活动开始时间">
			</el-table-column>
			<el-table-column prop="end_time" label="活动结束时间"></el-table-column>

			<!-- <el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="editSide(scope.row)">
						编辑关联活动</span
					>
				</template>
			</el-table-column> -->
		</el-table>
	</div>
</template>

<script>
import { recommendactivity } from "@/api/article";
import { status } from "nprogress";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		editSide() {},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
